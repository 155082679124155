import type { Participant, Survey } from "../MainTypes"
import { useEffect } from 'react'
import { useOutletContext } from "react-router-dom"
import ReactMarkdown from "react-markdown"
import Row from "./Row"
import { updateParticipant } from "../services/participantsService"

export default function SurveySummary() {
  const { survey, participant }: { survey: Survey, participant: Participant } = useOutletContext()

  useEffect(() => {
    updateParticipant(participant.id, { completed: true })
  }, [participant.id])

  return (
    <Row>
      <div id="section">
        <div id="summary-body">
          <ReactMarkdown>{survey.conclusion_page_content}</ReactMarkdown>
        </div>
      </div>
    </Row>
  )
}
