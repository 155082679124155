interface SectionNextButtonProps {
  text?: string
  onClick?: () => void
  icon?: string
}

export function SectionNextButton({
  text = "Next",
  onClick,
  icon,
}: SectionNextButtonProps) {
  return (
    <button
      type="submit"
      id="next"
      className="nav-button pull-right"
      onClick={onClick}
      style={{ display: "block" }}
    >
      <span className="button-text">{text}</span>
      <div className="circle-icon pull-right">
        <i className={`fa ${icon || "fa-angle-right"}`} />
      </div>
    </button>
  )
}

interface SectionPreviousButtonProps {
  onClick: () => void
}

export function SectionPreviousButton({ onClick }: SectionPreviousButtonProps) {
  return (
    <button
      id="back"
      className="nav-button pull-left"
      style={{ display: "block" }}
      onClick={onClick}
    >
      <div className="circle-icon pull-left">
        <i className="fa fa-angle-left" />
      </div>
      <span className="button-text">Back</span>
    </button>
  )
}

export default function SectionNavigationButtons({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div id="nav-buttons" className="clearfix">
      {children}
    </div>
  )
}
