import type { Dispatch, SetStateAction } from "react"
import type { Survey, Participant } from "../MainTypes"

import { useEffect } from "react"
import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
  Outlet,
} from "react-router-dom"
import invariant from "tiny-invariant"

import Row from "./Row"
import SectionTitle from "./SectionTitle"
import ParticipationAgreement from "./ParticipationAgreement"
import ParticipantForm from "./ParticipantForm"
import InstitutionForm from "./InstitutionForm"
import QuestionsSection from "./QuestionsSection"

interface OutletContext {
  survey: Survey
  setParticipant: Dispatch<SetStateAction<Participant | null>>
  participant: Participant | null
}

export default function SurveySection() {
  const { survey, setParticipant, participant }: OutletContext =
    useOutletContext()
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  invariant(
    params.section_id,
    "Must provide a section position in the route path"
  )

  const currentSectionId = parseInt(params.section_id)
  const currentSection = survey.sections.find(
    (section) => section.position === currentSectionId
  )
  const isImportSection =
    currentSection?.survey_items?.[0]?.itemable_type ===
    "WageDataFileUploadForm"

  useEffect(() => {
    if (isImportSection && !location.pathname.includes("import")) {
      navigate("import")
    }
    // eslint-disable-next-line
  }, [isImportSection])

  invariant(currentSection, "No current section for this position from route")

  const renderCurrentSectionItems = () => {
    if (currentSection.position === 1) {
      return (
        <ParticipationAgreement
          agreement={currentSection.survey_items[0].itemable}
        />
      )
    } else if (currentSection.position === 2) {
      return (
        <ParticipantForm
          sectionPosition={2}
          surveyId={survey.id}
          setParticipant={setParticipant}
          participant={participant}
        />
      )
    } else if (currentSection.position === 3) {
      invariant(participant, "No participant found")
      return (
        <InstitutionForm
          surveyId={survey.id}
          section={currentSection}
          participant={participant}
        />
      )
    } else if (isImportSection) {
      // This is handled by `import/*` routes
      return null
    } else {
      invariant(participant, "No participant found")
      const lastSectionPosition = survey.sections.at(-1)?.position as number

      return (
        <QuestionsSection
          participant={participant}
          section={currentSection}
          isLastSection={lastSectionPosition === currentSection.position}
        />
      )
    }
  }

  const isUploadSection = location.pathname.includes("csv-upload")
  return (
    <div id="section">
      <SectionTitle
        showSurveyTitle={currentSection.position === 1}
        showSectionTitle={!isImportSection || isUploadSection}
        surveyTitle={survey.title}
        sectionTitle={
          isUploadSection ? "Upload Your CSV File" : currentSection.title
        }
      />

      <Row>
        <div id="survey-items">
          <div className="survey-item">
            {renderCurrentSectionItems()}
            <Outlet
              context={{ survey, section: currentSection, participant }}
            />
          </div>
        </div>
      </Row>
    </div>
  )
}
