import type { Dispatch, SetStateAction } from "react"
import type { Participant } from "../MainTypes"

import { useReducer } from "react"
import { useNavigate } from "react-router-dom"

import useErrorsModal from "../utils/useErrorModal"
import ErrorsModal from "./ErrorsModal"
import Row from "./Row"
import { Input, createBuildFieldProps } from "./Forms"
import SectionNavigationButtons, {
  SectionNextButton,
  SectionPreviousButton,
} from "./SectionNavigationButtons"
import useParticipantFormTour from "../tours/useParticipantFormTour"
import { baseUrl, defaultHeaders } from "../config/fetch"
import logError from "../utils/logError"

interface ParticipantFormState {
  firstName: string
  lastName: string
  email: string
  phone: string
}

type ParticipantFormAction =
  | { type: "firstName"; value: string }
  | { type: "lastName"; value: string }
  | { type: "email"; value: string }
  | { type: "phone"; value: string }

function participantFormReducer(
  state: ParticipantFormState,
  action: ParticipantFormAction
) {
  switch (action.type) {
    case "firstName":
      return { ...state, firstName: action.value }
    case "lastName":
      return { ...state, lastName: action.value }
    case "email":
      return { ...state, email: action.value }
    case "phone":
      return { ...state, phone: action.value }
    default:
      return state
  }
}

interface ParticipantFormProps {
  surveyId: number
  sectionPosition: number
  setParticipant: Dispatch<SetStateAction<Participant | null>>
  participant: Participant | null
}

export default function ParticipantForm({
  surveyId,
  sectionPosition,
  setParticipant,
  participant,
}: ParticipantFormProps) {
  useParticipantFormTour()

  const navigate = useNavigate()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorsModal()
  const [state, dispatch] = useReducer(participantFormReducer, {
    firstName: participant?.first_name ?? "",
    lastName: participant?.last_name ?? "",
    email: participant?.email ?? "",
    phone: participant?.phone ?? "",
  })
  const buildFieldProps = createBuildFieldProps(state, dispatch)

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const payload = {
      participant: {
        first_name: state.firstName,
        last_name: state.lastName,
        email: state.email,
        phone: state.phone,
      },
    }

    try {
      let response = null

      if (participant) {
        response = await fetch(`${baseUrl}/participants/${participant.id}`, {
          method: "PATCH",
          body: JSON.stringify(payload),
          headers: defaultHeaders,
        })
      } else {
        response = await fetch(`${baseUrl}/surveys/${surveyId}/participants`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: defaultHeaders,
        })
      }

      const data = await response.json()

      if (response.ok) {
        setParticipant(data)
        navigate(`../sections/${sectionPosition + 1}`)
      } else if (data.includes("Email has already been taken")) {
        setErrorsState({
          errors: [
            "It looks like you've already started this survey. Refer to your welcome email from jordan@balancedcomp.com to re-enter the survey.",
          ],
          showErrors: true,
        })
      } else {
        setErrorsState({ errors: data, showErrors: true })
      }
    } catch (err) {
      logError(err as Error)
    }
  }

  return (
    <>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <form id="participant" onSubmit={submitForm}>
        <Row>
          <Input
            autoFocus
            placeholder="Enter your first name"
            label="First Name"
            onChange={(e) =>
              dispatch({ type: "firstName", value: e.target.value })
            }
            {...buildFieldProps("firstName")}
          />
          <Input
            placeholder="Enter your last name"
            label="Last Name"
            onChange={(e) =>
              dispatch({ type: "lastName", value: e.target.value })
            }
            {...buildFieldProps("lastName")}
          />
          <Input
            type="email"
            placeholder="An email address we can send a link to finish your survey"
            label="Email"
            onChange={(e) => dispatch({ type: "email", value: e.target.value })}
            {...buildFieldProps("email")}
          />
          <Input
            type="tel"
            placeholder="eg: (123) 316-9999"
            label="Work Phone"
            onChange={(e) => dispatch({ type: "phone", value: e.target.value })}
            {...buildFieldProps("phone")}
          />
        </Row>

        <Row>
          <SectionNavigationButtons>
            <SectionPreviousButton
              onClick={() => navigate(`../sections/${sectionPosition - 1}`)}
            />
            <SectionNextButton />
          </SectionNavigationButtons>
        </Row>
      </form>
    </>
  )
}
