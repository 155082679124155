export default function CSVExampleTable({ hasIncentiveData }: { hasIncentiveData: boolean }) {
  const incentiveDataHeaderStyles = hasIncentiveData ? {} : { display: 'none' }
  return (
    <table className="table wage-column-example">
      <thead>
        <tr>
          <th>Position</th>
          <th>Salary</th>
          <th style={incentiveDataHeaderStyles} className="incentive-example">
            Total Incentive Pay
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Teller</td>
          <td>$10.30</td>
          <td style={incentiveDataHeaderStyles} className="incentive-example">
            $300.00
          </td>
        </tr>
        <tr>
          <td>Teller</td>
          <td>$15.00</td>
          <td style={incentiveDataHeaderStyles} className="incentive-example">
            $300.00
          </td>
        </tr>
        <tr>
          <td>Teller</td>
          <td>$10.60</td>
          <td style={incentiveDataHeaderStyles} className="incentive-example">
            $300.00
          </td>
        </tr>
        <tr>
          <td>Branch Manager</td>
          <td>$24.04</td>
          <td style={incentiveDataHeaderStyles} className="incentive-example">
            $300.00
          </td>
        </tr>
        <tr>
          <td>CFO</td>
          <td>$130,000.00</td>
          <td style={incentiveDataHeaderStyles} className="incentive-example">
            $300.00
          </td>
        </tr>
      </tbody>
    </table>
  )
}
