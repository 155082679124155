import type { Survey, Section, Participant } from "../../MainTypes"
import { useState, useEffect } from "react"
import { Outlet, useOutletContext, useNavigate } from "react-router-dom"

export interface ParsedCSVDataRow {
  [header: string]: string | number
}

export interface ImportDataRow {
  positionTitle: string
  salaryInfo: number
  incentiveInfo: number[]
}

interface OutletContext {
  survey: Survey
  section: Section
  participant: Participant
}

export default function ImportSectionContainer() {
  const { survey, section, participant }: OutletContext = useOutletContext()
  const navigate = useNavigate()
  // Used in File Upload Step
  const [csvData, setCSVData] = useState<ParsedCSVDataRow[]>([])
  // Used in matching Step
  const [importData, setImportData] = useState<ImportDataRow[]>([])

  useEffect(() => {
    navigate("csv-upload")
    // eslint-disable-next-line
  }, [])

  return (
    <Outlet
      context={{
        survey,
        section,
        participant,
        csvData,
        setCSVData,
        importData,
        setImportData,
      }}
    />
  )
}
