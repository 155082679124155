import type { Participant, Section } from "../../MainTypes"
import type { ParsedCSVDataRow } from "../ImportSection/ImportSectionContainer"

import { useState, useRef } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { parse } from "papaparse"

import useImportUploadTour from "../../tours/useImportUploadTour"
import { baseUrl, defaultHeaders } from "../../config/fetch"
import Row from "../../components/Row"
import useErrorsModal from "../../utils/useErrorModal"
import logError from "../../utils/logError"
import ErrorsModal from "../ErrorsModal"
import Modal from "../Modal"
import SectionNavigationButtons, {
  SectionPreviousButton,
} from "../SectionNavigationButtons"
import CSVExampleTable from "./CSVExampleTable"

interface OutletContextProps {
  section: Section
  participant: Participant
  setCSVData: (data: ParsedCSVDataRow[]) => void
}

export default function ImportFileUpload() {
  useImportUploadTour()
  const navigate = useNavigate()
  const { participant, setCSVData, section }: OutletContextProps =
    useOutletContext()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorsModal()
  const [alreadyUploaded, setAlreadyUploaded] = useState<boolean>(
    participant.wage_data_points.length > 0 || participant.incentive_data_points.length > 0
  )

  const fileInput = useRef<HTMLInputElement>(null)

  const importFile = () => {
    if (!fileInput.current?.files?.length) return

    const files = fileInput.current?.files as FileList
    const file = files?.[0]
    const reader = new window.FileReader()

    reader.readAsDataURL(file)

    reader.onloadend = async () => {
      const isValidExt = file.name.split(".").pop() === "csv"

      if (!isValidExt) {
        setErrorsState({ errors: ["Must be .csv"], showErrors: true })
      } else {
        createWageDataFile(file, reader)
      }
    }
  }

  const createWageDataFile = async (file: File, fileReader: FileReader) => {
    const payload = {
      wage_data_file: {
        asset_file_name: file.name,
        asset_content_type: file.type.split("/").pop(),
        asset_file_size: file.size,
        asset: fileReader.result,
        participant_id: participant.id,
      },
    }

    try {
      const response = await fetch(`${baseUrl}/wage_data_files`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: defaultHeaders,
      })

      const data = await response.json()

      if (response.ok) {
        // destroy wage data file and data points
        await fetch(`${baseUrl}/participants/${participant.id}/destroy_data_file_and_points`, {
          method: "DELETE",
          headers: defaultHeaders,
        })

        parseCSVData(file, () => {
          navigate("../match-csv-columns")
        })
      } else {
        const errors = Array.isArray(data) ? data : [data]
        setErrorsState({ errors, showErrors: true })
      }
    } catch (err) {
      logError(err)
      setErrorsState({
        errors: ["Something went wrong uploading your CSV file"],
        showErrors: true,
      })
    }
  }

  const parseCSVData = (file: File, cb: () => void) => {
    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: { data: ParsedCSVDataRow[]; errors: any[] }) => {
        if (results.errors.length === 0) {
          setCSVData(results.data)
          cb()
        } else {
          console.error(results.errors)
        }
      },
      transformHeader: (header: string) => header,
      // keepEmptyRows: false
    })
  }

  return (
    <>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <Modal
        header="It looks like you've already imported and matched data"
        isOpen={alreadyUploaded}
        closeModal={() => setAlreadyUploaded(false)}
      >
        <div id='re-import'>
          <div id='import-nav' className='row'>
            <div id='nav-buttons' className='clearfix text-center'>
              <button 
                className='btn btn-lg btn-primary col-md-5'
                onClick={() => setAlreadyUploaded(false)}
              >
                I'd like to import again
              </button>
              <span className='separator col-md-2'>or</span>
              <button 
                className='btn btn-lg btn-primary col-md-5'
                onClick={() => navigate(`../../../sections/${section.position + 1}`)}
              >
                Skip to the next section
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Row>
        <div className="fileinput">
          <div className="upload-your-file">
            <h3>Upload your CSV file</h3>
            <Row>
              <form>
                <div className="form-group active">
                  <input
                    type="file"
                    ref={fileInput}
                    onChange={importFile}
                    name="wizard-csv-input"
                    accept=".csv"
                  />
                </div>
              </form>
            </Row>
          </div>
        </div>
      </Row>

      <Row style={{ marginTop: "4em" }}>
        <h3>What Needs to be included?</h3>
        <ul>
          <li>Position</li>
          <li>Salary</li>
          <li>
            Total Incentive (incentives, profit sharing, and/or commissions
          </li>
        </ul>
        <small>Please report your payouts effective 12/31/21</small>
      </Row>

      <Row style={{ marginTop: "4em" }}>
        <h3>Here's how it should look</h3>
        <CSVExampleTable 
          hasIncentiveData={section.survey_items[0].itemable.has_incentive_data} 
        />
      </Row>

      <Row>
        <a
          target="blank"
          href={`${baseUrl}/download-csv-template?template_type=combined`}
          style={{ display: "inline-block", fontSize: "14px" }}
          className="btn btn-lg btn-primary"
        >
          Download CSV Template
        </a>
      </Row>

      <Row style={{ marginTop: "4em" }}>
        <div id="upload-csv-security-statement">
          <h3>Wait, is this secure?</h3>
          <p>
            Yes; everything is secured with 256 bit encryption, using the
            National Institute of Standards and Technology recommended 2048 bit
            certificate signatures. Geek speak for "we're as secure as your
            mobile banking site."
          </p>
        </div>
      </Row>

      <SectionNavigationButtons>
        <SectionPreviousButton
          onClick={() => navigate(`../../../sections/${section.position - 1}`)}
        />
      </SectionNavigationButtons>
    </>
  )
}
