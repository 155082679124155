import type { ImportDataRow } from "./ImportSectionContainer"
import type { MatchStatus } from "./MatchImportCSVColumns"
import type { Survey, Section, Participant } from "../../MainTypes"

import { uniqWith, isEqual, sortBy } from "lodash"
import { useState, useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"

import useImportPositionMatchingTour from "../../tours/useImportPositionMatchingTour"
import { defaultHeaders, baseUrl } from "../../config/fetch"
import useErrorModal from "../../utils/useErrorModal"
import logError from "../../utils/logError"
import updateArrayAtIndex from "../../utils/updateArrayAtIndex"
import SectionNavigationButtons, {
  SectionNextButton,
  SectionPreviousButton,
} from "../SectionNavigationButtons"
import { Select } from "../Forms"
import ErrorsModal from "../ErrorsModal"

interface Department {
  id: number
  title: string
}

interface BenchmarkPosition {
  id: number
  title: string
  department_title: string
  department_id: number
}

interface MatchRow {
  index: number
  status: MatchStatus | "Pristine"
  active: boolean
  data: ImportDataRow
  matchedPositionId: string | null
  matchedDepartmentId: string | null
}

interface OutletContext {
  importData: ImportDataRow[]
  section: Section
  survey: Survey
  participant: Participant
}

export default function MatchBenchmarkPositions() {
  useImportPositionMatchingTour()
  const navigate = useNavigate()
  const { errorsState, setErrorsState, resetErrorsState } = useErrorModal()
  const { importData, section, survey, participant }: OutletContext =
    useOutletContext()

  // Setup Matching Rows From Import Data
  const [matchingDataRows, setMatchingDataRows] = useState<MatchRow[]>(
    importData.map((dataRow, idx) => ({
      index: idx,
      status: idx === 0 ? "Unmatched" : "Pristine",
      active: idx === 0,
      data: dataRow,
      matchedPositionId: null,
      matchedDepartmentId: null,
    }))
  )
  // Benchmark Position and Departments Data
  const [benchmarkPositions, setBenchmarkPositions] = useState<
    BenchmarkPosition[]
  >([])
  const departments: Department[] = sortBy(
    uniqWith(
      benchmarkPositions.map((position) => ({
        id: position.department_id,
        title: position.department_title,
      })),
      isEqual
    ),
    ["title"]
  )

  // Fetch BenchmarkPositions
  useEffect(() => {
    fetch(
      `${baseUrl}/benchmark_positions?survey_item_id=${section.survey_items[0].id}`
    )
      .then((res) => res.json())
      .then(setBenchmarkPositions)
      .catch(logError)
    // eslint-disable-next-line
  }, [])

  const getMatchingRowClass = (row: MatchRow) => {
    if (row.status === "Unmatched") {
      return "unmatched"
    } else if (row.status === "Skipped") {
      return "unmatched skipped"
    } else {
      return "matched"
    }
  }

  const onSkipOrEdit = (row: MatchRow) => {
    const clickingEdit = row.status === "Skipped" || row.status === "Pristine"
    let newRows = [...matchingDataRows]

    // If we're editing a previously matched row just update it
    // to avoid weird active/status rows
    if (row.status === "Matched") {
      setMatchingDataRows(
        updateArrayAtIndex(newRows, row.index, {
          ...row,
          active: true,
          status: "Unmatched",
          matchedPositionId: null,
          matchedDepartmentId: null,
        })
      )
      return
    }

    if (clickingEdit) {
      newRows = updateArrayAtIndex(newRows, row.index, {
        ...row,
        active: true,
        status: "Unmatched",
      })
    } else {
      newRows = updateArrayAtIndex(newRows, row.index, {
        ...row,
        active: false,
        status: "Skipped",
      })
    }

    // If you're not clicking edit on a pristine item further in the list and
    // If the next one after one being skipped/edited
    // hasn't been touched yet, set it active
    if (
      row.status !== "Pristine" &&
      newRows[row.index + 1]?.status === "Pristine"
    ) {
      newRows = updateArrayAtIndex(newRows, row.index + 1, {
        ...newRows[row.index + 1],
        active: true,
        status: "Unmatched",
      })
    }

    setMatchingDataRows(newRows)
  }

  const onPositionSelect = (positionId: string, row: MatchRow) => {
    let newRows = updateArrayAtIndex(matchingDataRows, row.index, {
      ...row,
      matchedPositionId: positionId,
      active: false,
      status: "Matched",
    })
    // Make next section active if possible
    if (matchingDataRows[row.index + 1]?.status === "Pristine") {
      newRows = updateArrayAtIndex(newRows, row.index + 1, {
        ...matchingDataRows[row.index + 1],
        active: true,
        status: "Unmatched",
      })
    }

    setMatchingDataRows(newRows)
  }

  const getPositionsForDepartmentId = (departmentId: string | null) => {
    return benchmarkPositions.filter(
      (position) => position.department_id.toString() === departmentId
    )
  }

  const onSubmit = async () => {
    const anyUnmatched = matchingDataRows.find(
      (row) => row.status === "Unmatched" || row.status === "Pristine"
    )

    if (anyUnmatched) {
      setErrorsState({
        errors: ["Must match or skip all rows"],
        showErrors: true,
      })
      return
    }

    const matchedRows = matchingDataRows.filter(
      (row) => row.status === "Matched"
    )

    const dataPoints = matchedRows.map((row) => ({
      benchmark_position_id: Number(row.matchedPositionId),
      title: row.data.positionTitle,
      annual_wages: row.data.salaryInfo,
      wages: row.data.incentiveInfo.reduce((acc, num) => acc + num, 0),
      participant_id: participant.id,
      survey_id: survey.id,
    }))

    const wageDataPointRequests = dataPoints.map((dataPoint) => {
      return fetch(
        `${baseUrl}/participants/${participant.id}/wage_data_points`,
        {
          method: "POST",
          body: JSON.stringify(dataPoint),
          headers: defaultHeaders,
        }
      )
    })
    const incentiveDataPointRequests = dataPoints.map((dataPoint) => {
      return fetch(
        `${baseUrl}/participants/${participant.id}/incentive_data_points`,
        {
          method: "POST",
          body: JSON.stringify(dataPoint),
          headers: defaultHeaders,
        }
      )
    })

    await Promise.all([...wageDataPointRequests, ...incentiveDataPointRequests])
      .then((responses) => {
        const failedRequest = responses.find((r) => !r.ok)
        if (failedRequest) {
          Promise.reject(failedRequest)
        } else {
          Promise.resolve(responses)
        }
      })
      .catch(logError)

    const lastSectionPosition = survey.sections.at(-1)?.position as number
    if (section.position === lastSectionPosition) {
      navigate(`../../../survey-summary`)
    } else {
      navigate(`../../../sections/${section.position + 1}`)
    }
  }

  return (
    <>
      <ErrorsModal
        messages={errorsState.errors}
        isOpen={errorsState.showErrors}
        closeModal={resetErrorsState}
      />
      <div className="instructions">
        <div className="instructions-title">
          <div className="section-title">
            <h2>Time to match!</h2>
            <p>
              Use the catalog below to determine which title fits your position.
            </p>
          </div>
        </div>
      </div>
      <div className="description-library">
        <iframe
          title="BalancedComp Job Description Library"
          src="https://jobdescriptions.lewisandclark.io"
          style={{ width: "100%", height: "300px", border: 0 }}
        />
      </div>

      <table id="match-benchmark-positions" className="table">
        <thead>
          <tr>
            <th>Positions You Imported</th>
            <th />
            <th>
              <h4 className="slide-header">Step 1</h4>
              <p style={{ margin: 0 }}>Select the closest matching</p>
              <strong>Department</strong>
            </th>
            <th>
              <h4 className="slide-header">Step 2</h4>
              <p style={{ margin: 0 }}>Select the closest matching</p>
              <strong>Position</strong>
            </th>
          </tr>
        </thead>

        <tbody>
          {matchingDataRows.map((row) => {
            return (
              <tr
                key={row.index}
                className={`${getMatchingRowClass(row)} ${
                  row.active && "active"
                }`}
              >
                <td className="position-title">
                  <span className="position-title-text">
                    {row.data.positionTitle}&nbsp;
                  </span>
                  <span className="position-title-count">
                    (
                    {
                      matchingDataRows.filter(
                        (p) => p.data.positionTitle === row.data.positionTitle
                      ).length
                    }
                    )
                  </span>
                </td>

                <td className="skip-actions">
                  <span
                    className={
                      row.status === "Skipped" || row.status === "Matched"
                        ? "reset-matching"
                        : "skip-matching"
                    }
                    onClick={(e) => {
                      onSkipOrEdit(row)
                    }}
                  >
                    {row.status === "Unmatched" ? "Skip" : "Edit"}
                  </span>
                </td>
                {row.status !== "Pristine" && (
                  <>
                    <td className="category-select">
                      {row.status === "Matched" &&
                        departments.find(
                          (d) => d.id.toString() === row.matchedDepartmentId
                        )?.title}
                      {row.status === "Skipped" && null}
                      {row.status === "Unmatched" && (
                        <div className="form-group active">
                          <Select
                            value={row.matchedDepartmentId || ""}
                            onChange={(e) => {
                              setMatchingDataRows(
                                updateArrayAtIndex(
                                  matchingDataRows,
                                  row.index,
                                  {
                                    ...row,
                                    matchedDepartmentId: e.target.value,
                                  }
                                )
                              )
                            }}
                            className="department-title"
                            id="departmentTitle"
                            name="departmentTitle"
                          >
                            <option value="" hidden disabled>
                              Department
                            </option>
                            {departments.map((department) => (
                              <option key={department.id} value={department.id}>
                                {department.title}
                              </option>
                            ))}
                          </Select>
                        </div>
                      )}
                    </td>

                    <td className="benchmark-select">
                      {row.status === "Matched" &&
                        benchmarkPositions.find(
                          (p) => p.id.toString() === row.matchedPositionId
                        )?.title}
                      {row.status === "Skipped" && "Skipped"}
                      {row.status === "Unmatched" && (
                        <div className="form-group">
                          <Select
                            id="positionTitle"
                            name="positionTitle"
                            className="benchmark-positions"
                            value={row.matchedPositionId || ""}
                            onChange={(e) =>
                              onPositionSelect(e.target.value, row)
                            }
                          >
                            <option value="" disabled hidden>
                              Position
                            </option>
                            {getPositionsForDepartmentId(
                              row.matchedDepartmentId
                            ).map((position) => (
                              <option key={position.id} value={position.id}>
                                {position.title}
                              </option>
                            ))}
                          </Select>
                        </div>
                      )}
                    </td>
                  </>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>

      <SectionNavigationButtons>
        <SectionNextButton icon="fa-check" onClick={onSubmit} />
        <SectionPreviousButton
          onClick={() => navigate("../match-csv-columns")}
        />
      </SectionNavigationButtons>
    </>
  )
}
