import type { Dispatch, SetStateAction } from "react"
import type { Survey, Participant } from "./MainTypes"
import { Outlet } from "react-router-dom"

import Column from "./components/Column"
import Row from "./components/Row"
import CookieAndPrivacyPolicy from "./components/CookieAndPrivacyPolicy"
import { SurveyTitle } from "./components/SurveyTitle"
import { SurveyCredit } from "./components/SurveyCredit"
import { baseUrl } from "./config/fetch"

const jobCatalogURL =
  process.env.REACT_APP_BUILD_DOMAIN === "compsurvey.cbai.com"
    ? process.env.REACT_APP_CBAI_JOB_DESCRIPTION_URL
    : process.env.REACT_APP_COMP_JOB_DESCRIPTION_URL

interface AppProps {
  survey: Survey
  setParticipant: Dispatch<SetStateAction<Participant | null>>
  participant: Participant | null
}

export default function AppLayout({
  survey,
  setParticipant,
  participant,
}: AppProps) {
  return (
    <div>
      <header>
        <div className="container">
          <Row>
            <Column screenSize="md" width="12">
              <div className="header-contents">
                <span className="logo">
                  <img
                    alt="BalancedComp"
                    src={`${process.env.PUBLIC_URL}/logo-bc.svg`}
                  />
                </span>
                <span className="site-title">by BalancedComp</span>

                <div className="linkgroup">
                  <a rel="noreferrer" href={jobCatalogURL} target="_blank">
                    Job Catalog <i className="fa fa-download" />
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={`${baseUrl}/surveys/${survey.id}/download`}
                  >
                    Survey Preview <i className="fa fa-download" />
                  </a>
                  <a
                    id="beacon-support-icon-trigger"
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:support@balancedcomp.com?subject=${survey.title}%20Support`}
                  >
                    Contact Support
                  </a>
                </div>
              </div>
            </Column>
          </Row>
        </div>
      </header>

      <div id="main-body" className="container">
        <Outlet context={{ survey, setParticipant, participant }} />
      </div>

      <div className='container'>
        <footer>
          <Row>
              <Column screenSize="md" width="4">
                <SurveyTitle title={"My Survey"} />
              </Column>
              <Column screenSize="md" width="4">
                <CookieAndPrivacyPolicy />
              </Column>
              <Column screenSize="md" width="4">
                <SurveyCredit />
              </Column>
          </Row>
        </footer>
      </div>
    </div>
  )
}
